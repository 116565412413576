import React, {useState} from "react";
import {connect} from "react-redux";
import {
    addDonorPagesSelected, removeDonorPagesSelected,
    updateDonorPagesSelected
} from "../../app/redux/slices/sitesSlice";
import "./ItemPageReplaceOptions.css";

const ItemPageReplaceOptionsContainer = ({
                                    updateDonorPagesSelected,
                                    id,
                                    donorPagesSelected
                                }) => {
    const {replace} = donorPagesSelected.find(item => item.id === id);

    const onChangeReplace = (key, value, rowReplace) => {
        let replaceLocal = replace.map(item => ({...item}));
        replaceLocal[rowReplace][key] = value;

        updateDonorPagesSelected({
            id,
            replace: replaceLocal
        });
    };

    const removeReplace = (rowReplace) => {
        let replaceLocal = replace.filter((item, index) => index !== rowReplace);

        updateDonorPagesSelected({
            id,
            replace: replaceLocal
        });
    }

    return <div className="pages-item-replace-options">
        {replace.map((item, rowReplace) => <div key={`page-wrapper-replace-option-${rowReplace}`}>
            <div className="grid pages-item__row">
                <label htmlFor="fromReplace">
                    From replace
                    <input value={item?.from || ""}
                           onChange={(e) => {
                               onChangeReplace("from", e.target.value, rowReplace)
                           }}
                           type="text"
                           id="fromReplace"
                           name="fromReplace"
                           placeholder="from replace"
                           required
                    />
                </label>

                <label htmlFor="toReplace">
                    to replace
                    <input
                        onChange={(e) => {
                            onChangeReplace("to", e.target.value, rowReplace)
                        }}
                        value={item?.to || ""}
                        type="text"
                        id="toReplace"
                        name="toReplace"
                        placeholder="to replace"
                        required
                    />
                </label>
                <label>
                    <button
                        className="pages-item__remove-options"
                        onClick={() => {
                            removeReplace(rowReplace)
                        }}
                    >- row
                    </button>
                </label>
            </div>
        </div>)}
        <button
            className="pages-item__add-options"
            onClick={() => {
                const updatedReplace = {
                    id,
                    replace: [...replace, {
                        from: "",
                        to: "",
                    }]
                }
                updateDonorPagesSelected(updatedReplace)
            }}
        >+ row</button>
    </div>;
}

const mapStateToProps = (state) => ({
    donorPagesSelected: state.sites.donorPagesSelected,
})

export default connect(mapStateToProps, {
    updateDonorPagesSelected,
    addDonorPagesSelected,
    removeDonorPagesSelected,
})(ItemPageReplaceOptionsContainer);