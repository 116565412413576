import React from "react";
import ListPages from "./ui/ListPages";
import {connect} from "react-redux";

const ListDonorPages = ({list, count}) => {
    return <ListPages list={list} count={count} />;
}

const mapStateToProps = (state) => ({
    list: state.sites.donorPages,
    count: state.sites.donorPagesCount
});

export default connect(mapStateToProps, null)(ListDonorPages);