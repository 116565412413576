import React, {useEffect} from "react";
import {connect} from "react-redux";
import {fetchSites} from "../../app/redux/slices/sitesSlice";
import {Link} from "react-router-dom";
import ButtonTest from "../../widgets/ButtonTest/ButtonTest";

const HomePage = ({sites, fetchSites}) => {
    useEffect(() => {
        fetchSites()
    }, []);

    if (!sites) return <div aria-busy={!sites} />

    return <div className="content">
        <ButtonTest/>
        <table>
            <thead>
            <tr>
                <th>id</th>
                <th>domain</th>
            </tr>
            </thead>
            <tbody>
            {sites.map(item => <tr key={`tr-site-${item.id}`}>
                <th key={`id-site-${item.id}`} scope="row">{item.id}</th>
                <th key={`domain-site-${item.id}`} scope="row"><Link to={`/site/${item.id}`}>{item.domain}</Link></th>
            </tr>)}
            </tbody>
        </table>
    </div>
}

const mapStateToProps = (state) => ({
    sites: state.sites.sites
})

const mapDispatchToProps = ({
    fetchSites: fetchSites
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);