import React from "react";
import {connect} from "react-redux";
import "./LogsView.css";
import { nanoid } from '@reduxjs/toolkit';

const LogsView = ({translateLogs}) => {
    return <div className="logs-view">
        <div className="logs-view__list">
            {translateLogs.map(log => <div key={nanoid()} className={`logs-view__item logs-view__item--${log.type}`}><span>{log.type.toUpperCase()}:</span>{log.message}</div>)}
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    translateLogs: state.ws.translateLogs
})

export default connect(mapStateToProps, null)(LogsView);