import api from "./request";

export const getSites = async () => {
    return await api.get('sites')
}

export const getSitePage = async (siteId) => {
    return await api.get('get-site-pages',{ params: { siteId } })
}

export const translateSitePages = async (data) => {
    return await api.post('translate-site-pages', data);
}