import {getDeeplAccounts, getDeeplAccUsage} from "../request/deepl";
import {setAccUsage, setDeeplUsersList} from "../redux/slices/deeplSlice";
import {put, select} from 'redux-saga/effects';
import {setSitesLoading} from "../redux/slices/sitesSlice";

export function* fetchDeeplAccUsageData() {
    yield put(setSitesLoading(true));
    try {
        const userId = yield select(state => state.deepl.userId);
        const response = yield getDeeplAccUsage(userId);
        yield put(setAccUsage(response));
    } catch (e) {
        console.log(e)
    }
    yield put(setSitesLoading(false));
}

export function* fetchDeeplAccountsData() {
    yield put(setSitesLoading(true));
    try {
        const response = yield getDeeplAccounts();
        yield put(setDeeplUsersList(response));
    } catch (e) {
        console.log(e)
    }
    yield put(setSitesLoading(false));
}