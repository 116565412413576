import React from "react";
import {connect} from "react-redux";
import ItemPageReplaceOptions from "../ItemPageReplaceOptions/ItemPageReplaceOptionsContainer";
import {
    addDonorPagesSelected,
    removeDonorPagesSelected
} from "../../app/redux/slices/sitesSlice";

const DonorPageItem = ({
                           item,
                           donorPagesSelected,
                           addDonorPagesSelected,
                           removeDonorPagesSelected,
                       }) => {
    const checked = !!donorPagesSelected.find(page => page.id === item.id);

    return <div>
        <fieldset>
            <label htmlFor={`${item.id}-page`}>
                <input
                    onChange={(e) => {
                        const checked = e.target.checked;

                        if (checked) {
                            addDonorPagesSelected({
                                id: item.id,
                                replace: []
                            });
                        } else {
                            removeDonorPagesSelected(item.id);
                        }
                    }}
                    checked={checked}
                    type="checkbox"
                    id={`${item.id}-page`}
                    name={`${item.id}-page`}
                />
                [ID: {item.id}] Title: {item?.title?.rendered}
            </label>
        </fieldset>
        {checked && <ItemPageReplaceOptions id={item.id} />}
        <hr/>
    </div>
}

const mapStateToProps = (state) => ({
    donorPagesSelected: state.sites.donorPagesSelected,
})

export default connect(mapStateToProps, {
    addDonorPagesSelected,
    removeDonorPagesSelected,
})(DonorPageItem);