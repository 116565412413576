import React from "react";
import {connect} from "react-redux";
import "./ProfileInfo.css";

const ProfileInfo = ({user, isLogIn}) => {

    if (!isLogIn) return

    return <div className="profile-info">
        <div>Hello {user.name}</div>
        <div>{user.email}</div>
    </div>
}

const mapStateToProps = (state) => ({
    user: state.user.data,
    isLogIn: state.user.isLogIn
})

export default connect(mapStateToProps, null)(ProfileInfo)