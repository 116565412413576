import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {setSourceLang, setTargetLang} from "../../app/redux/slices/sitesSlice";
import languages from "language-list"
import "./LanguagesSelect.css";

const LanguagesSelect = ({
                             setSourceLang,
                             setTargetLang,
                             sourceLang,
                             targetLang,
}) => {
    const [codes, setCodes] = useState([]);
    useEffect(() => {
        if (!codes.length) {
            const codes = languages().getLanguageCodes().map(i => i.toUpperCase());
            setCodes(codes)
        }
    }, []);

    const options = codes.map(code => <option value={code} key={`lang-key-${code}`}>
        {code}
    </option>);

    return <div className="language-select">
        <div>
            <label htmlFor="donor-lang">Donor Lang</label>
            <select
                onChange={(e) => setSourceLang(e.currentTarget.value)}
                id="donor-lang"
                required
                value={sourceLang}
            >
                <option value="" disabled>Select donor lang</option>
                {options}
            </select>
        </div>
        <div>
            <label htmlFor="consumer-lang">Consumer Lang</label>
            <select
                id="consumer-lang"
                required
                value={targetLang}
                onChange={(e) => setTargetLang(e.currentTarget.value)}
            >
                <option value="" disabled>Select consumer lang</option>
                {options}
            </select>
        </div>
    </div>
};

const mapStateToProps = (state) => ({
    sourceLang: state.sites.sourceLang,
    targetLang: state.sites.targetLang,
})

export default connect(mapStateToProps, {
    setSourceLang,
    setTargetLang,
})(LanguagesSelect);