import React from "react";
import {connect} from "react-redux";
import {testFetch} from "../../app/redux/slices/testSlice";

const ButtonTest = ({testFetch}) => {
    return <button onClick={() => {testFetch()}}>
            Test request
        </button>
};

export default connect(null, {
    testFetch
})(ButtonTest)