import { eventChannel } from 'redux-saga';
import { put, take, call } from 'redux-saga/effects';
import {wsClose, wsMessage, wsOpen} from "../redux/slices/wsSlice";
import io from "socket.io-client"
import {hideSiteLoadingWS, showSiteLoadingWS} from "../redux/slices/sitesSlice";

const eventsAndActions = [
    {
        event: 'logsTranslatedPages',
        actionType: wsMessage.type
    },
    {
        event: 'logsTranslatedPagesInProgress',
        actionType: showSiteLoadingWS.type
    },
    {
        event: 'logsTranslatedPagesFinished',
        actionType: hideSiteLoadingWS.type
    },
]

function createWebSocketConnection() {
    const socket = io.connect(process.env.REACT_APP_WS_URL, {
        withCredentials: true,
    });

    return eventChannel((emit) => {
        socket.on("connect", () => {
            console.log('ws connected, id = ' + socket.id);
            emit({ type: wsOpen.type });
        });

        socket.on("disconnect", () => {
            console.log('ws disconnected');
            emit({ type: wsClose.type });
        });

        eventsAndActions.forEach(item => {
            socket.on(item.event, (data) => {
                console.log('ws event type: ' + item.event);
                emit({ type: item.actionType, payload: data })
            })
        })

        return () => {
            socket.disconnect();
        };
    });
}

function* handleWebSocketConnection() {
    const channel = yield call(createWebSocketConnection);

    while (true) {
        const action = yield take(channel);
        yield put(action);
    }
}

export default function* websocketSaga() {
    yield call(handleWebSocketConnection);
}