import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga'
import testSlice from "./slices/testSlice";
import mainSaga from "../sagas/mainSaga";
import userSlice from "./slices/userSlice";
import sitesSlice from "./slices/sitesSlice";
import deeplSlice from "./slices/deeplSlice";
import wsSlice from "./slices/wsSlice";

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
    reducer: {
        test: testSlice,
        user: userSlice,
        sites: sitesSlice,
        deepl: deeplSlice,
        ws: wsSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});


// then run the saga
sagaMiddleware.run(mainSaga)