import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLogIn: false,
    data: null
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        getUserData: () => {},
        setUserData: (state, { payload }) => {
            state.data = payload
        },
        setIsLogIn: (state, { payload }) => {
            state.isLogIn = payload;
        }
    }
});

export const { setIsLogIn, getUserData, setUserData } = userSlice.actions;

export default userSlice.reducer;
