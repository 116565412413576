import React, {useState} from "react";
import {Link} from "react-router-dom";

const SidebarMenu = () => {
    const [open, setOpen] = useState(false)

    return <div className="sidebar-menu">
        <ul className="sidebar-menu__list list-item-menu">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/translator">Site translator</Link></li>
        </ul>

    </div>
}

export default SidebarMenu