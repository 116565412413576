import React from "react";
import "./ListPages.css";
import DonorPageItem from "../../DonorPageItem/DonorPageItem";
import {connect} from "react-redux";
import {fetchTranslateSitePages} from "../../../app/redux/slices/sitesSlice";

const ListPages = ({list, count, isDisabledSubmit, fetchTranslateSitePages}) => {

    if (list === undefined) return <div aria-busy={true} />;

    if (!list) return;

    return <div className="list-pages">
        <div>Count: {count}</div>
        <hr />
        <form>
            <ul>
                <DonorPageItem item={{
                    id: 'page-replace',
                    title: {
                        rendered: 'Global replace options'
                    }
                }} />
                {list.map(item => <li key={`page-index-${item.id}`}>
                    <DonorPageItem item={item} />
                </li>)}
            </ul>
            <div className="submit-page__wrapper">
                <div className="submit-page__container">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            fetchTranslateSitePages();
                        }}
                        disabled={isDisabledSubmit}>Translate page</button>
                </div>
            </div>
        </form>
    </div>;
};

const mapStateToProps = (state) => ({
    isDisabledSubmit: !state.sites.donorPagesSelected.filter(i => i.id !== 'page-replace').length || !state.sites.targetLang.length,
});

export default connect(mapStateToProps, {
    fetchTranslateSitePages
})(ListPages);