import {call, fork, put, takeEvery, takeLatest} from 'redux-saga/effects'
import {fetchDonorPages, fetchSites, fetchTranslateSitePages} from "../redux/slices/sitesSlice";
import {fetchUserData} from "./userSaga";
import {fetchDonorPagesData, fetchSitesData, fetchTranslateSitePagesData} from "./sitesSaga";
import {fetchDeeplAccountsData, fetchDeeplAccUsageData} from "./deeplSaga";
import {fetchDeeplAccounts, fetchDeeplAccUsage} from "../redux/slices/deeplSlice";
import {testFetchData} from "./testSaga";
import {testFetch} from "../redux/slices/testSlice";
import websocketSaga from "./wsSaga";

function* mainSaga() {
    yield fork(fetchUserData)

    yield fork(websocketSaga)

    yield takeEvery(fetchSites.type, fetchSitesData)
    yield takeEvery(fetchDonorPages.type, fetchDonorPagesData)
    yield takeEvery(fetchTranslateSitePages.type, fetchTranslateSitePagesData)
    yield takeEvery(fetchDeeplAccUsage.type, fetchDeeplAccUsageData)
    yield takeEvery(fetchDeeplAccounts.type, fetchDeeplAccountsData)

    // test saga
    yield takeEvery(testFetch.type, testFetchData)
}

export default mainSaga