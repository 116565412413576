import {setIsLogIn, setUserData} from "../redux/slices/userSlice";
import {getUserApi} from "../request/getUser";
import {put} from "redux-saga/effects";

export function* fetchUserData() {
    try {
        const userData = yield getUserApi();
        yield put(setIsLogIn(true));
        yield put(setUserData(userData));
    } catch (e) {
        console.log(e)
    }
}