import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    accUsage: null,
    userId: null,
    deeplUsersList: null,
};

export const deeplSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        setUserId: (state, { payload }) => {
            state.userId = payload;
        },
        setDeeplUsersList: (state, { payload }) => {
            state.deeplUsersList = payload;
        },
        setAccUsage: (state, {payload}) => {
            state.accUsage = payload;
        },
        fetchDeeplAccUsage: () => {},
        fetchDeeplAccounts: () => {},
    }
});

export const {
    setUserId,
    setDeeplUsersList,
    setAccUsage,
    fetchDeeplAccUsage,
    fetchDeeplAccounts,
} = deeplSlice.actions;

export default deeplSlice.reducer;
