import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const testSlice = createSlice({
    name: 'test',
    initialState,
    reducers: {
        testFetch: () => {},
    },
});

export const { testFetch } = testSlice.actions;

export default testSlice.reducer;
