import {put, select} from "redux-saga/effects";
import {getSitePage, getSites, translateSitePages} from "../request/sites";
import {
    setDonorPages,
    setDonorPagesCount,
    setSites, setSitesLoading
} from "../redux/slices/sitesSlice";
import {dataSitesNormalize} from "../../proccesses/sites";

export function* fetchSitesData() {
    try {
        let sites = yield getSites();
        yield put(
            setSites(
                dataSitesNormalize(sites)
            )
        );
    } catch (e) {
        console.log(e)
    }
}

export function* fetchDonorPagesData() {
    try {
        // loading
        yield put(setDonorPages(undefined))

        const siteId = yield select((state) => state.sites.donorId);
        const {items, count} = yield getSitePage(siteId);
        yield put(setDonorPagesCount(count));
        yield put(setDonorPages(items));
    } catch (e) {
        console.log(e)
    }
}

export function* fetchTranslateSitePagesData() {
    yield put(setSitesLoading(true));
    try {
        const pageGlobalReplaceId = 'page-replace';
        const pagesParams = yield select(state => state.sites.donorPagesSelected.filter(item => item.id !== pageGlobalReplaceId));
        const globalReplace = yield select(state => {
            const replace = state.sites.donorPagesSelected.find(item => item.id === pageGlobalReplaceId);
            return replace?.replace
        });
        const donorId = yield select(state => state.sites.donorId);
        const consumerId = yield select(state => state.sites.consumerId);
        const sourceLang = yield select(state => state.sites.sourceLang);
        const targetLang = yield select(state => state.sites.targetLang);
        const deeplAccUserId = yield select(state => state.deepl.userId);

        const response = yield translateSitePages({
            pagesParams,
            globalReplace,
            consumerId,
            donorId,
            sourceLang,
            targetLang,
            deeplAccUserId,
        });
    } catch (e) {
        console.log(e)
    }
}