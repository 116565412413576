// slices/websocketSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isConnected: false,
    message: null,
    translateLogs: [],
};

const websocketSlice = createSlice({
    name: 'websocket',
    initialState,
    reducers: {
        wsOpen: (state) => {
            state.isConnected = true;
        },
        wsClose: (state) => {
            state.isConnected = false;
        },
        wsMessage: (state, action) => {
            const data = JSON.parse(action.payload)
            state.translateLogs = [...state.translateLogs, data];
        },
    },
});

export const { wsOpen, wsClose, wsMessage } = websocketSlice.actions;
export default websocketSlice.reducer;