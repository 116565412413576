import api from "./request";

export const getDeeplAccUsage = async (userId) => {
    return await api.get('deepl-acc-usage', {
        params: {userId}
    })
}

export const getDeeplAccounts = async () => {
    return await api.get('deepl-accounts');
}