import React from "react";
import {Link, BrowserRouter} from "react-router-dom";
import './App.css';
import {connect} from "react-redux";
import ProfileInfo from "./widgets/ProfileInfo/ui/ProfileInfo";
import SidebarMenu from "./widgets/SidebarMenu/SidebarMenu";
import RoutesGroup from "./widgets/Routes/Routes";
import Loader from "./widgets/Loader/Loader";

function App({isLogIn}) {
  const googleAuth = () => {
    window.open(
        process.env.REACT_APP_AUTH_URL,
        "_self"
    );
  };

  return <>
    <BrowserRouter>
      <main className="container-fluid">
        <header>
          <ProfileInfo/>
        </header>
        <div className="content-wrapper">
          <SidebarMenu/>
          <div className="content">
            {isLogIn
                ? <RoutesGroup/>
                : <button onClick={googleAuth}>
                  <span>Sing in with Google</span>
                </button>}
          </div>
        </div>
        <footer/>
        <Loader/>
      </main>
    </BrowserRouter>
  </>
}

const mapStateToProps = (state) => ({
  isLogIn: state.user.isLogIn
})

export default connect(mapStateToProps, null)(App);
