import React, {useEffect} from "react";
import {connect} from "react-redux";
import {
    fetchDonorPages,
    fetchSites,
    setConsumerId,
    setDonorId
} from "../../app/redux/slices/sitesSlice";
import "./SiteTranslator.css";
import ListDonorPages from "../../widgets/ListPages/ListDonorPages";
import LanguagesSelect from "../../widgets/LanguagesSelect/LanguagesSelect";
import LogsView from "../../widgets/LogsView/ui/LogsView";

const SiteTranslator = ({
                            fetchSites,
                            setDonorId,
                            setConsumerId,
                            consumerId,
                            donorId,
                            sites,
                            fetchDonorPages,
                        }) => {
    useEffect(() => {
        fetchSites()
    }, []);

    if (!sites) return <div aria-busy={!sites}/>;

    return <div className="site-translator-page__wrapper">
        <h5>Processed logs</h5>
        <LogsView />
        <div className="site-translator-page">
            <div className="site-translator-page__item">
                <label htmlFor="donor">Donor</label>
                <select
                    value={donorId || ""}
                    onChange={(e) => {
                        const id = e.currentTarget.value;
                        setDonorId(id);
                        fetchDonorPages()
                    }}
                    id="fruit"
                    required
                >
                    <option key={`key-donor-default`} value="" disabled>Choose a donor ...</option>
                    {sites.map(item =>
                        <option
                            key={`key-donor-${item.id}`}
                            value={item.id}
                        >
                            {item.domain}
                        </option>
                    )}
                </select>
                <div>
                    <ListDonorPages />
                </div>
            </div>
            <div className="site-translator-page__item">
                <label htmlFor="consumer">Consumer</label>
                <select
                    value={consumerId || ""}
                    onChange={(e) => {
                        const id = e.currentTarget.value
                        setConsumerId(id);
                    }}
                    id="consumer"
                    required
                >
                    <option key={`key-consumer-default`} value="" disabled>Choose a consumer ...</option>
                    {sites.map(item =>
                        <option
                            key={`key-consumer-${item.id}`}
                            value={item.id}
                        >
                            {item.domain}
                        </option>
                    )}
                </select>
                <article>
                    <header>
                        <p>Select languages</p>
                    </header>
                    <LanguagesSelect/>
                </article>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    consumerId: state.sites.consumerId,
    donorId: state.sites.donorId,
    sites: state.sites.sites,
});

export default connect(mapStateToProps, {
    fetchSites,
    setDonorId,
    setConsumerId,
    fetchDonorPages,
})(SiteTranslator)