import React from "react";
import {Route, Routes} from "react-router-dom";
import HomePage from "../../pages/HomePage/HomePage";
import SiteTranslator from "../../pages/SiteTranslator/SiteTranslator";

const RoutesGroup = () => {
    return <Routes>
        <Route path="" element={<HomePage />} />
        <Route path="/translator" element={<SiteTranslator />} />
    </Routes>
}

export default RoutesGroup