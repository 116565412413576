import React from "react";
import "./Loader.css"
import {connect} from "react-redux";

const Loader = ({sitesLoading}) => {
    if (!sitesLoading) return "";

    return <div aria-busy={sitesLoading} className="loader"/>;
};

const mapStateToProps = (state) => ({
    sitesLoading: state.sites.loading
});

export default connect(mapStateToProps, null)(Loader);